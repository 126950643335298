<div>
  @if (userProfileSchema$ | async; as userProfileSchema) {
    <smallstack-form [schema]="userProfileSchema" [(value)]="userData" (valueChange)="updateEmailPreview()">
      <smallstack-form-table [showActions]="false" [showHeader]="false" [compactMode]="true"> </smallstack-form-table>
      <smallstack-form-errors></smallstack-form-errors>
    </smallstack-form>
  } @else {
    <smallstack-loader></smallstack-loader>
  }
  @if (!existingUser && userData?.loginType === "email") {
    <div class="form-control">
      <label class="label cursor-pointer checkbox-label-fix">
        <input
          type="checkbox"
          [checked]="sendWelcomeMail"
          (change)="sendWelcomeMail = $any($event.srcElement).checked"
          class="checkbox"
          data-test="send-welcome-mail"
        />
        <span class="label-text">Willkommens-E-Mail versenden</span>
      </label>
    </div>
  }
  @if (sendWelcomeMail) {
    <div class="form-control">
      <label class="label"> <span class="label-text"> Wählen Sie eine E-Mail Vorlage </span></label>
      <select
        class="select select-bordered w-full mt-2 mb-4"
        [(ngModel)]="welcomeMailTemplateId"
        (ngModelChange)="updateEmailPreview()"
        [ngModelOptions]="{ standalone: true }"
        data-test="welcome-mail-template-select"
      >
        @for (template of emailTemplateStore.value$ | async; track template) {
          <option [value]="template.id" attr.data-test="welcome-mail-template-{{ template.name }}">{{
            template.name
          }}</option>
        }
      </select>
    </div>
    @if (welcomeMailTemplateId) {
      <div>
        <label class="label font-bold">E-Mail Vorschau</label>
        <div [innerHtml]="welcomeEmailPreview" class="bg-slate-200 p-4 rounded-md mb-2"></div>
      </div>
    }
  }
  @if (showCreateBtn) {
    <button class="btn btn-primary btn-block" data-test="create-user-button" [loadingFn]="createUser()">
      Erstellen
    </button>
  }
</div>
