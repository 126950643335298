import { JSONSchema7 } from "json-schema";

export const defaultUserProfileSchema: Readonly<JSONSchema7> = {
  type: "object",
  properties: {
    firstName: {
      type: "string",
      minLength: 2,
      title: "@@user.profile.firstname"
    },
    lastName: {
      type: "string",
      minLength: 2,
      title: "@@user.profile.lastname"
    }
  },
  additionalProperties: true
};
