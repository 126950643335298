import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  viewChild
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AxiosApiClient, UserDto, UsersApi } from "@smallstack/axios-api-client";
import {
  ConfigurationStore,
  EmailTemplateStore,
  EnvironmentKeys,
  EnvironmentService,
  LoadingElementDirective
} from "@smallstack/common-components";
import { BackofficeConfigurationKey, defaultUserProfileSchema } from "@smallstack/core-common";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { LoaderComponent } from "@smallstack/store-components";
import { TypeSchema, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { BooleanEquationOperator, cloneObject, isEmptyObject, isJsonString, replaceVariables } from "@smallstack/utils";
import { FormComponent, FormErrorsComponent, SchemaFormTableComponent } from "@smallstack/widget-core";
import { Observable, Subscription, from } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "smallstack-create-user-dialog",
  templateUrl: "./create-user-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    LoadingElementDirective,
    LoaderComponent,
    FormComponent,
    SchemaFormTableComponent,
    FormErrorsComponent
  ]
})
export class CreateUserDialogComponent implements OnInit, OnDestroy {
  @Input()
  public showCreateBtn: boolean = true;

  public form = viewChild(FormComponent);

  public userData: { [key: string]: any; email: string };
  protected existingUser: UserDto;
  @Input()
  public set user(user: UserDto) {
    if (user && !isEmptyObject(user)) {
      this.existingUser = user;
      this.userData = {
        email: user.primaryEmailAddress,
        ...user.profile
      };
    }
  }
  public userProfileSchema$: Observable<TypeSchema>;
  protected sendWelcomeMail: boolean = false;
  protected welcomeMailTemplateId: string;
  protected welcomeEmailPreview: SafeHtml;
  private subscription = new Subscription();

  constructor(
    public configurationStore: ConfigurationStore,
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService,
    @Optional() private matDialogRef: MatDialogRef<CreateUserDialogComponent>,
    protected emailTemplateStore: EmailTemplateStore,
    private translationStore: TranslationStore,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private environmentService: EnvironmentService
  ) {
    void emailTemplateStore.preload();
  }

  public ngOnInit(): void {
    this.userProfileSchema$ = from(
      this.configurationStore.getConfiguration(BackofficeConfigurationKey.USER_PROFILE_SCHEMA)
    ).pipe(
      map((customSchema: string) => {
        const profileSchema = isJsonString(customSchema)
          ? JSON.parse(customSchema)
          : cloneObject(defaultUserProfileSchema);
        // if no current user is set, add email
        if (!this.existingUser)
          profileSchema.properties = {
            loginType: {
              type: "string",
              title: "Wie meldet sich der Benutzer an?",
              default: "email",
              "x-schema-form": {
                inputWidget: WIDGET_FORM_INPUT_SELECT,
                values: [
                  {
                    value: "email",
                    label: "E-Mail & Passwort"
                  },
                  {
                    value: "username",
                    label: "Benutzername & Passwort"
                  }
                ]
              }
            } as SchemaFormPropertyOptions,
            username: {
              type: "string",
              title: "Benutzername",
              description:
                'Da das System dennoch eine E-Mail Adresse benötigt, wird eine automatische E-Mail Adresse in der Form "{benutzername}@noreply-app-user.{projekt-id}.smallstack.app" ohne Funktion generiert.',
              "x-schema-form": {
                rules: [
                  {
                    if: { dataPath: "../loginType", operator: BooleanEquationOperator.NOT_EQUALS, value: "username" },
                    action: "hide"
                  }
                ]
              }
            } as SchemaFormPropertyOptions,
            email: {
              type: "string",
              format: "email",
              title: "E-Mail",
              "x-schema-form": {
                rules: [
                  {
                    if: { dataPath: "../loginType", operator: BooleanEquationOperator.NOT_EQUALS, value: "email" },
                    action: "hide"
                  }
                ]
              }
            } as SchemaFormPropertyOptions,
            ...profileSchema.properties
          };
        return profileSchema;
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  protected async updateEmailPreview(): Promise<void> {
    if (this.welcomeMailTemplateId) {
      const template = await this.emailTemplateStore.get(this.welcomeMailTemplateId);
      if (template) {
        this.welcomeEmailPreview = this.sanitizer.bypassSecurityTrustHtml(
          replaceVariables(this.translationStore.translate(template.content), {
            profile: this.userData,
            backofficeUrl: this.environmentService.get(EnvironmentKeys.BACKOFFICE_URL),
            token: "##Passwort Reset Token##"
          })
        );
        this.cdr.markForCheck();
      }
    }
  }

  public createUser() {
    return async (): Promise<any> => {
      let returnValue: UserDto;
      const profile = cloneObject(this.userData);
      delete profile.email;
      delete profile.username;
      delete profile.loginType;
      if (!this.existingUser) {
        returnValue = await this.notificationService.handlePromise(
          this.axiosApiClient
            .get(UsersApi)
            .createUser({
              createUser: {
                username: this.userData.username,
                emails: this.userData.email ? [{ address: this.userData.email }] : undefined,
                profile,
                welcomeMailTemplateId: this.welcomeMailTemplateId
              }
            })
            .then((res) => res.data)
        );
      } else {
        this.existingUser.profile = profile;
        returnValue = await this.notificationService.handlePromise(
          this.axiosApiClient
            .get(UsersApi)
            .putUser({
              id: this.existingUser.id,
              user: this.existingUser
            })
            .then((res) => res.data)
        );
      }
      if (this.matDialogRef) this.matDialogRef.close(true);
      return returnValue;
    };
  }
}
