import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { FormService } from "../../services/form.service";

@Component({
  selector: "smallstack-form-errors",
  standalone: true,
  templateUrl: "./form-errors.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorsComponent {
  protected formService = inject(FormService);
  protected validationErrors = computed(() => {
    return Object.values(this.formService.validationErrors());
  });
}
